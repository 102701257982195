:root {
	--primary-color: #c5560a;
	--primary-color-opacity: rgba(249, 200, 1, 0.5);
	--primary-color-dark: #f47825;
	--secondary-color: #1d1d1f;
	--ternary-color: #4ca862;
}

@import url("bootstrap.css");

@media (max-width: 767.98px) {
	.order-mobile-first {
		order: -1;
	}

	.hero-background {
		background-position: 80% center !important;
		background-size: cover !important;
	}

	.navbar-inside-div {
		justify-content: center !important;
	}
}

@media (max-width: 991.98px) {
	.navbar {
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 1030;
	}
	body {
		padding-top: 100px;
	}
}

.btn-width {
	min-width: 150px;
}

.hero-background {
	background: url("../images/stock/heartland-grooming-co-hero.jpg") no-repeat center 85% / cover, rgba(29, 29, 31, 0.3);
	background-blend-mode: multiply;
	height: 70vh;
}

.image-transition {
	justify-self: center;
	max-height: 250px;
	width: auto;
	max-width: 75%;
}

.logo-footer {
	max-width: 250px;
}

.navbar-border {
	border-bottom: 4px solid var(--primary-color); /* Adjust the color and thickness as needed */
}

.nav-logo {
	width: 300px;
	height: auto;
}

.primary-background {
	background-color: var(--primary-color);
}

.secondary-background {
	background-color: var(--secondary-color);
}

.ternary-background {
	background-color: var(--ternary-color);
}

.text-gray {
	color: #d3d3d3 !important;
}

.text-third {
	color: var(--ternary-color) !important;
}

#inputQuantity {
	max-width: 3rem;
}

form {
	width: 30vw;
	min-width: 500px;
	box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
	border-radius: 7px;
	padding: 40px;
}

#payment-message {
	color: rgb(255, 0, 0);
	font-size: 16px;
	line-height: 20px;
	padding-top: 12px;
	text-align: center;
}

#payment-element {
	margin-bottom: 24px;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
	border-radius: 50%;
}

.spinner {
	color: #ffffff;
	font-size: 22px;
	text-indent: -99999px;
	margin: 0px auto;
	position: relative;
	width: 20px;
	height: 20px;
	box-shadow: inset 0 0 0 2px;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
}

.spinner:before,
.spinner:after {
	position: absolute;
	content: "";
}

.spinner:before {
	width: 10.4px;
	height: 20.4px;
	background: #5469d4;
	border-radius: 20.4px 0 0 20.4px;
	top: -0.2px;
	left: -0.2px;
	-webkit-transform-origin: 10.4px 10.2px;
	transform-origin: 10.4px 10.2px;
	-webkit-animation: loading 2s infinite ease 1.5s;
	animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
	width: 10.4px;
	height: 10.2px;
	background: #5469d4;
	border-radius: 0 10.2px 10.2px 0;
	top: -0.1px;
	left: 10.2px;
	-webkit-transform-origin: 0px 10.2px;
	transform-origin: 0px 10.2px;
	-webkit-animation: loading 2s infinite ease;
	animation: loading 2s infinite ease;
}

/* LoadingScreen.css */
.loading-screen {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	z-index: 9999;
	transition: opacity 0.5s ease-in-out;
	opacity: 1;
}

.loading-screen.hidden {
	opacity: 0;
	pointer-events: none;
}

.app-container.hidden {
	visibility: hidden;
	opacity: 0;
}

.app-container.visible {
	visibility: visible;
	opacity: 1;
	transition: opacity 0.5s ease-in-out;
}

.spinner-image {
	width: 80px; /* Adjust size as needed */
	height: 80px; /* Adjust size as needed */
	animation: spin 2s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

#testimonials-section {
	/* min-height: 500px; */
}

.testimonial-slider {
	position: relative;
	min-height: 150px; /* Ensure a minimum height */
	height: auto; /* Allow the height to grow based on content */
	overflow: hidden;
}

.testimonial {
	position: absolute;
	width: 100%;
	opacity: 0;
	transform: translateX(100%);
	transition: transform 0.7s ease, opacity 0.7s ease;
	padding: 10px 10px;
}

.testimonial.active {
	opacity: 1;
	transform: translateX(0);
	position: relative;
}

.testimonial.inactive {
	opacity: 0;
	transform: translateX(-100%);
}

@keyframes loading {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@media only screen and (max-width: 600px) {
	form {
		width: 80vw;
		min-width: initial;
	}
}

.img-responsive,
.thumbnail > img,
.thumbnail a > img,
.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
	display: block;
	max-width: 100%;
	height: auto;
}

.product-main-image {
	margin-bottom: 20px;
}
.product-main-image img {
	width: 100%;
}

/* BEGIN product page */
.product-page {
	background: #fff;
	padding: 22px;
	position: relative;
	margin-right: 10px;
}
.product-main-image {
	margin-bottom: 20px;
}
.product-main-image img {
	width: 100%;
}
.product-other-images {
	text-align: left;
}
.product-other-images img {
	width: 58px;
	height: auto;
	margin: 0 12px 12px 0;
}
.product-other-images a:hover img,
.product-other-images a.active img {
	box-shadow: 0 0 0 2px #c7ced5;
}
.product-page h1 {
	border-bottom: 1px solid #f4f4f4;
	padding-bottom: 12px;
}
.price-availability-block {
	border-bottom: 1px solid #f4f4f4;
	padding-bottom: 12px;
	margin-bottom: 17px;
}
.price-availability-block .price {
	float: left;
	font-family: "PT Sans Narrow", sans-serif;
}
.price-availability-block .price strong {
	color: #e84d1c;
	font-size: 35px;
	font-weight: normal;
}
.price-availability-block .price strong span {
	font-size: 25px;
}
.price-availability-block .price em {
	font-style: normal;
	color: #bbb;
	font-size: 17px;
}
.price-availability-block .price em span {
	font-size: 23px;
	text-decoration: line-through;
}
.price-availability-block .availability {
	float: right;
	color: #7b8a99;
}
.price-availability-block .availability strong {
	font-weight: normal;
	color: #3e4d5c;
}
.product-page .nav-tabs > li {
	margin-top: 1px;
}
.product-page-options {
	border-top: 1px solid #f4f4f4;
	border-bottom: 1px solid #f4f4f4;
	padding: 20px 0;
	margin-bottom: 20px;
	width: 100%;
	overflow: hidden;
}
.product-page-options .pull-left {
	margin-right: 40px;
}
.product-page-options .pull-left:last-child {
	margin-right: 0;
}
.product-page-options label {
	font-weight: normal;
	text-transform: uppercase;
	color: #8e9ca8;
	font-family: "PT Sans Narrow", sans-serif;
	float: left;
	margin-right: 10px;
	padding-top: 2px;
}
.product-page-options select.input-sm {
	float: left;
	width: auto;
	height: 26px;
}

.product-page-cart {
	border-bottom: 1px solid #f4f4f4;
	padding-bottom: 20px;
	margin-bottom: 18px;
}
.product-quantity,
.product-quantity .input-group {
	width: 70px;
	float: left;
	margin-right: 20px;
	position: relative;
}
table .product-quantity,
table .product-quantity .input-group {
	margin-right: 0;
}
.product-page-cart .btn {
	padding: 7px 20px;
	font-size: 13px;
	height: 38px;
}
.product-quantity input.form-control {
	border: none;
	background: #edeff1 !important;
	font: 300 23px "Open Sans", sans-serif;
	color: #647484;
	height: 38px;
	width: 50px;
	text-align: center;
	padding: 5px;
}
.product-quantity input.form-control:focus {
	border: none;
}

.product-quantity .input-group-btn {
	position: static;
}
.product-quantity .btn {
	text-align: center;
	height: 18px !important;
	width: 18px;
	padding: 0 2px 0 1px !important;
	text-align: center;
	background: #edeff1;
	border-radius: 0 !important;
	font-size: 18px !important;
	line-height: 1 !important;
	color: #616b76;
	margin: 0 !important;
	position: absolute;
	right: 0;
}
.product-quantity .quantity-up {
	top: 0;
}
.product-quantity .quantity-down {
	bottom: 0;
}
.product-quantity .btn i {
	position: relative;
	top: -2px;
	left: 1px;
}

.product-page .review {
	color: #6e7a85;
	font-family: "Open Sans", sans-serif;
	border-bottom: 1px solid #f4f4f4;
	padding-bottom: 18px;
	margin-bottom: 20px;
	font-weight: 300;
}

.product-page .rateit {
	margin-right: 27px;
	position: relative;
	top: 2px;
}
.product-page .review a {
	color: #e6400c;
}
.product-page .social-icons li a {
	background-position: 0 -38px;
	opacity: 1;
}
.content-page p,
.content-page .list-unstyled {
	margin-bottom: 20px;
}
.content-page .list-unstyled .list-unstyled {
	margin-left: 20px;
	padding-top: 3px;
	margin-bottom: 0;
}
.content-page .list-unstyled li {
	margin-bottom: 3px;
}
.footer .list-inline > li:last-child {
	padding-right: 0;
}
.ecommerce .footer .pull-right {
	padding-top: 10px;
}

.bg-texture-01-black {
	background-image: url("../images/texture/texture01.png");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	/* opacity: 0.1; */
}

.bg-texture-02-primary {
	background-image: url("../images/texture/texture02.png");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	/* opacity: 0.1; */
}

.bg-texture-03-light {
	background-image: url("../images/texture/texture03.png");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	/* opacity: 0.1; */
}

.bg-texture-04-white {
	background-image: url("../images/texture/texture04.png");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	/* opacity: 0.1; */
}
.bg-texture-04-light {
	background-image: url("../images/texture/texture05.png");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	/* opacity: 0.1; */
}

.ingredient-icon {
	max-height: 200px;
	width: auto;
}

/* Container for the menu tab */
.menu-tab {
	position: fixed;
	top: 50%;
	right: 0;
	width: 50px;
	height: 50px;
	background-color: white;
	border-radius: 5px 0 0 5px;
	transform: translateY(-50%);
	cursor: pointer;
	z-index: 9999; /* Ensure it's above other content */
	text-shadow: 2px 2px;
}

/* Hidden modal for the menu */
.side-menu {
	position: fixed;
	top: 0;
	right: -100%; /* Hidden off-screen */
	width: 80%; /* Adjust this for the width you want */
	height: 100%;
	background-color: white;
	box-shadow: -5px 0 15px rgba(0, 0, 0, 0.3);
	transition: right 0.3s ease-in-out; /* Smooth slide-in transition */
	z-index: 10000; /* Ensure it's above the tab */
}

/* Content inside the menu */
.side-menu-content {
	padding: 20px;
	height: 100%;
	overflow-y: auto; /* Allow scrolling if content is too tall */
}

/* When the menu is active (visible) */
.side-menu.active {
	right: 0; /* Slide the menu into view */
}

/* Optional: Overlay to dim the background when the menu is open */
.menu-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
	z-index: 9998; /* Behind the menu but above the rest of the content */
}

/* styles/App.css */

.mobile-about-shop,
.mobile-cart-overlay {
	display: none;
}

/* Mobile View: display and position the cart button overlay */
@media (max-width: 767px) {
	.mobile-cart-overlay {
		display: block;
		position: fixed;
		bottom: 20px;
		right: 20px;
		z-index: 1000;
	}

	.mobile-about-shop {
		display: block;
		position: fixed;
		top: 20px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 1000;
	}

	.mobile-cart-overlay .cart-button {
		/* Adjust the styling for mobile if needed */
		padding: 15px;
	}

	.mobile-cart-button {
		/* background-color: rgba(255, 255, 255, 0.5); */
	}

	.row.no-gutters {
		margin-right: 0;
		margin-left: 0;
	}
	.row.no-gutters > [class*="col-"] {
		padding-right: 0;
		padding-left: 0;
	}
}
@media (max-width: 1199px) {
	.list-group-item .discount-input-group {
		flex-wrap: wrap;
	}

	.list-group-item .discount-input-group input,
	.list-group-item .discount-input-group button {
		/* width: 100%; */
		margin-bottom: 0.5rem;
	}
}

.discount-input-group {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.discount-input-group button {
	margin-left: 10px;
}

.discount-input-group input {
	padding: 0.375rem 0.75rem; /* Matches the Bootstrap .btn padding */
	line-height: 1.5; /* Matches the Bootstrap .btn line-height */
	border: 1px solid #000; /* Matches the default Bootstrap input border */
	border-radius: 0.25rem; /* Matches the default Bootstrap border radius */
	font-size: 1rem; /* Ensures the font size is consistent */
	height: calc(1.5em + 0.75rem + 2px); /* Calculate based on padding and border */
}

.discount-input-group .btn {
	line-height: 1.5;
	height: calc(1.5em + 0.75rem + 2px); /* Ensure height matches input */
	border: 1px solid #000; /* Matches the default Bootstrap input border */
}

form#payment-form {
	padding: 0; /* Remove padding */
	box-shadow: none; /* Remove any shadow */
	width: 100%;
}
